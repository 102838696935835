<template>
  <div class="entry_text">
    <div class="t1">1.Download the Entry Materials</div>
    Visit the official website of ECI Awards to download relevant entry
    materials . Before preparing your entry, please read all entry materials
    carefully to better understand the definition for each category. <br />
    <a
      href="http://www.eciawards.org/Public/uploads/match/16608805376761.zip"
      target="_blank"
    >
      <img src="@/assets/images/download2.png"
    /></a>

    <div>
      If you need entry materials in traditional Chinese, please click the
      button below.
    </div>
    <a
      href="http://www.eciawards.org/Public/uploads/match/16551152861884.zip"
      target="_blank"
    >
      <img src="@/assets/images/download3.png"
    /></a>
    <br />
    <br />
    <div class="t1">2.Create an Account</div>
    Visit the homepage of the ECI Awards official website or click the following
    link to enter the online entry system:<br />
    <a
      href="http://www.eciawards.org/Home/Login/index.html?lang=en"
      target="_blank"
      >http://www.eciawards.org/Home/Login/index.html?lang=en</a
    ><br />

    <br />
    (Entrants of ECI Awards 2021 could log in directly; new entrants for ECI
    Awards 2022 please sign up first, and then follow the instructions to create
    an account. )
    <br /><br />
    <div class="t1">3.Provide Entry Details</div>
    Provide the following details after creating an account:
    <div class="t2">①Basic Information</div>
    Provide basic information of your entries. Ensure your answers in the online
    entry system match your answers in the entry form. Please keep in mind the
    entry title you submit will be publicly announced if your case is a finalist
    or winner.
    <div class="t2">②Credits</div>
    If you are submitting in-house work, please list your company as the primary
    contributor. If you are an agency or a vendor, please provide your client’s
    information in relevant section. List other contributors if applicable.
    Credits may be published in announcements of finalists and winners, on
    certificates and trophies, in case study and ECI innovation Index. You
    should communicate with the PR department and/or other relevant departments
    of your company and your credited partner companies to ensure all company
    names are credited correctly. Entrants are solely responsible for the
    accuracy of the credits. Credits submitted at the time of uploading your
    entry will be used when announcing finalists/ winners, making
    trophies/certificates, compiling collections, and tallying Index results. So
    before entering any information, we suggest you to confirm with your
    company, your partner/client as well as other contributor in order to
    provide correct information. Entrants are responsible for their own
    information accuracy. Note: Information you entered in the online entry
    credits section will be considered final. The application of credits change
    will be considered by ECI Awards Committee at discretion.
    <div class="t2">③Case Summary</div>
    The case summary will be used in global promotion and case display later on
    if your entry becomes a finalist or winner. So it is important that the case
    summary is thoroughly reviewed for spelling, grammar, and accuracy. It
    should summarize background, objectives, innovative ideas, execution and
    (expected) results of your case.
    <div class="t2">④Publishing Policy & Permission</div>
    Entrants that become finalists and winners of 2020 ECI Awards will be
    showcased in various ways. By entering your work in the competition, the ECI
    Awards is automatically granted the right to make copies of, edit and
    display case summary and other materials including texts, images and videos,
    for education and publicity purposes. If there is any confidential
    information you do not want to be published, please delete it before
    submitting your case. Work submitted must be original and you must have
    secured rights to submit it.
    <br /><br />
    <div class="t1">4.Upload Relevant Forms and Materials</div>
    All case materials should be submitted online. You may edit or delete your
    entry materials at any time until you click the final Submit button. The
    table below indicates formatting requirements of all case materials. Please
    prepare all your case materials in strict accordance with these
    requirements.
    <br />
    <br />
    <table class="table_">
      <tr>
        <td colspan="2">
          Formatting Requirements for Uploading Files: Important notices:
          <br />After completing entry details of an entry, an entry ID will be
          generated automatically (you can find it at My Entries > Entry ID).
          Please use this entry ID to name your effort when uploading all files
          (entry form, video, PPT, image, company logos), for example,
          SI-ES-1302.
        </td>
      </tr>
      <tr>
        <td>Item</td>
        <td>Formatting Requirements</td>
      </tr>
      <tr>
        <td>Entry Form</td>
        <td>
          1. Upload your entry form in both PDF and DOC format;<br />
          2. Type answers in black 10-point font, and underline, bold or use red
          font color to highlight;<br />
          3. In the event of a question that is not applicable, please indicate
          the reason directly under the question, instead of deleting or
          skipping that question;<br />
          4. The total number of pages of the entry form shall not exceed 10
          pages with less than 5 photos inserted, and the file size should be no
          bigger than 3 MB
        </td>
      </tr>
      <tr>
        <td>
          Case Video or PPT<br />
          Note: video and PPT are supplementary materials, you have to choose at
          least one form to upload.In order to enable judges to better
          understand your effort and achieve higher score, we recommend you to
          upload both. Supplementary materials uploading preference:<br />
          Both video & PPT > Only video > Only PPT
        </td>
        <td>
          Requirements for Case Video<br />
          1.Upload a video in .mp4 format and shorter than 3 minutes (not
          exceeding 100 MB in file size);<br />
          2. Do not include any work that you do not have the rights to;<br />
          Requirements for PPT<br />
          1.Upload a showcase PPT (not exceeding 30 pages);<br />
          2. File size should not exceed 50 MB.
        </td>
      </tr>
      <tr>
        <td>Case Image</td>
        <td>
          1.Upload one photo that best represents your case or product;<br />
          2.Technical Requirements: 300 dpi .jpg (not exceeding 10 MB file
          size).
        </td>
      </tr>
      <tr>
        <td>Company LOGO</td>
        <td>
          1.Provide logos of your company, you client (if there is any) or other
          involving third-party company;<br />
          2.Technical Requirements: only .ai or .psd format is acceptable.
        </td>
      </tr>
    </table>
    <br />
    <div class="t1">5.Payment Instructions</div>
    You may pay for your entries once entry details are completed. Check the
    entries you are paying for and provide information for the invoice/receipt
    you need from the ECI Awards.<br />
    The button to submit your entry is deactivated unless your payment is
    successfully made. We recommend paying through PayPal in which way the
    button will be activated automatically after your payment, or you can also
    transfer entry fees to the account shown below and send the payment proof to
    international@eciawards.org for us to confirm and activate the button.<br />
    Please include “ECI Awards” and provide entry ID as well as the name of
    payer/participating company in the note/memo/comment when you make the
    payment.<br /><br />
    Payment options:
    <br />
    <div style="border: 1px solid #ccc; padding: 20px">
      1. Name of Bank: Bank of America<br />
      Bank Address: 7387 Day Creek Blvd. Rancho Cucamonga, CA 91739<br />
      ABA Routing Number: 121000358<br />
      WIRE Routing Number: 026009593<br />
      SWIFT CODE (International): BOFAUS3N<br />
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;BOFAUS3NXXX<br />
      Account Name: INTERNATIONAL ENTREPRENEURS, CREATIVES & INNOVATORS
      ASSOCIATION INC<br />
      Checking Account Number: 325135203088<br />
      Zelle: (909)319-4982<br /><br />

      2. PayPal Account: international@eciawards.org
    </div>
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
