<template>
  <div class="entry_text">
    If your case becomes a 2023 ECI finalist or winner, the credits submitted
    will be used to tally the 2023 ECI Innovation Index results according to the
    standard the chart below shows. Based on the ECI Innovation Index, the
    yearly global rankings of innovative abilities of companies in digital
    business, we will honor the best innovative companies of the year.

    <br />
    <br />
    <table class="table_">
      <tr style="background: #2b8bc3; color: #fff">
        <td width="300">Award Level</td>
        <td width="300">Points</td>
      </tr>
      <tr>
        <td>Grand ECI</td>
        <td>12</td>
      </tr>
      <tr>
        <td>Best Marketing Innovation of the Year</td>
        <td>10</td>
      </tr>
      <tr>
        <td>Gold</td>
        <td>6</td>
      </tr>
      <tr>
        <td>Silver</td>
        <td>4</td>
      </tr>
      <tr>
        <td>Bronze</td>
        <td>2</td>
      </tr>
      <tr>
        <td>Finalist</td>
        <td>1</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
.table_ tr:nth-child(odd) {
  background: #eaeef3;
}
</style>
