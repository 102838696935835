<template>
  <div class="entry_text">
    ECI Awards is the only awards platform with background in investment,
    technology R&D/operations, as well as marketing. We gather experts from
    different industries to judge and critique innovative projects to set the
    benchmark for entrepreneurship, creativity and innovation. Now we are
    calling for nominations and self-recommendations for 2021 ECI Awards Jury
    which will be reviewed by ECI Awards Presidium and ECI Awards Organizing
    Committee. The final lineup of 2021 ECI Awards Jury will be announced later.
    The specific rules and procedures are shown as follows.

    <br />
    <br />
    <div class="t1">Rules of nomination and self-recommendation:</div>
    1. Chief supervisors and chief judges are invited by ECI Organizing
    Committee and normally self-recommendation for these roles will be
    dismissed.
    <br />2. We are looking for: <br />a. Decision makers of companies which are
    former ECI Awards winners. <br />b. Experts on investment with over 10 years
    of relevant experience, cases of investment in A shares or IPOs, or the role
    of managing funds of millions of U.S. dollars. <br />c. Experts on R&D from
    industry-leading tech companies. Owners of IPs are preferred. <br />d.
    Experts on marketing from industry-leading brands, media and agencies, who
    are decision makers of their companies and manage marketing expenses of
    millions of U.S. dollars annually. <br />e. Experts from research institute,
    academy or industry association with over 10 years of consulting experience.
    <br /><br />Note: In order to ensure the impartiality and professionalism of
    ECI Awards,
    <u
      >experts on investment will take up 20% of the 2021 ECI Awards Jury,
      experts on technology R&D 30%, experts on marketing 40%, and researchers
      10%.</u
    ><br />
    ECI Awards Presidium and ECI Awards Organizing Committee reserve the right
    to optimize the jury lineup.
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
