<template>
  <div class="entry_text">
    <div class="t1">1. Gold/Silver/Bronze Level Prize</div>
    Each sub-category will generally produce one gold-, two silver- and three
    bronze-level winners (vacancy and increase in winner’s quota are possible).
    The Best Marketing Innovation of the Year will be chosen among the gold
    winners of the Category of Marketing Innovation (vacancy is possible). The
    Grand ECI winner will be selected among the winner of the Best Marketing
    Innovation of the Year and the gold winners of the other categories (vacancy
    is still possible).
    <br />
    <br />
    <div class="t1">2. Best Marketing Innovation of the Year</div>
    The gold-level winners among 15 sub-categories in Marketing Innovation are
    eligible for the Best Marketing Innovation of the Year — the highest prize
    in Marketing Innovation. The Grand ECI winner will be selected among the
    winner of the Best Marketing Innovation of the Year and the gold winners of
    the other categories (vacancy is still possible).
    <br />
    <br />
    <div class="t1">3. Grand ECI</div>
    The Grand ECI represents the single best case among all the gold winners,
    and vacancy is allowed. There has to be something disruptive about it —
    whether it be a business model that has reshaped the industry, a new
    technology that has achieved outstanding results, or a new marketing
    approach to engage consumers... All in all, the Grand ECI winner must be the
    most innovative case of the year that has the highest
    commercial/technological/social values and is likely to change the way of
    our life.
    <br />
    <br />
    <div class="t1">4. Specialty Categories Awards</div>
    The Specialty Categories are designed to address a specific business
    situation or new challenge, and the awards in this category are not fixed.
    If a Specialty Category contains sub-categories, then each sub-category will
    have one gold, two silver and three bronze level winners (vacancy is
    possible).
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
