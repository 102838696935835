<template>
  <div class="entry_box main">
    <div class="container content">
      <div class="title_box">
        <div class="title">
          {{ detail.dTitle }}
        </div>
      </div>

      <!---->
      <E1 v-if="detail.id == 1"></E1>
      <E2 v-if="detail.id == 2"></E2>
      <E3 v-if="detail.id == 3"></E3>
      <E4 v-if="detail.id == 4"></E4>
      <E5 v-if="detail.id == 5"></E5>
      <E6 v-if="detail.id == 6"></E6>
      <E7 v-if="detail.id == 7"></E7>
      <E8 v-if="detail.id == 8"></E8>
      <E9 v-if="detail.id == 9"></E9>
      <div class="backBtn" @click="pageBack"></div>
    </div>
  </div>
</template>

<script>
import { DATA } from '@/data/a_entry.js';
import E1 from './components/entry/e1.vue';
import E2 from './components/entry/e2.vue';
import E3 from './components/entry/e3.vue';
import E4 from './components/entry/e4.vue';
import E5 from './components/entry/e5.vue';
import E6 from './components/entry/e6.vue';
import E7 from './components/entry/e7.vue';
import E8 from './components/entry/e8.vue';
import E9 from './components/entry/e9.vue';

export default {
  name: 'Entry',
  components: { E1, E2, E3, E4, E5, E6, E7, E8, E9 },
  data() {
    return { id: this.$route.query.id, detail: {} };
  },
  created() {
    this.getDetail();
  },
  methods: {
    getDetail() {
      this.detail = DATA.find(item => {
        return item.id == this.id;
      });

      console.log(this.detail);
    },
    pageBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss" scoped>
.title_box {
  .title {
    font-size: 36px;
    line-height: 40px;
    font-weight: bold;
  }
  .download {
    float: right;
    margin-top: 15px;
    cursor: pointer;
  }
}
</style>
