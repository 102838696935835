<template>
  <div class="entry_text">
    <div class="entry_banner">
      <img src="@/assets/images/entry.png" />
    </div>
    The ECI Awards founded in 2014 was established jointly by Chinese scholars
    and global digital innovators from 27 countries and regions activated by an
    educational institute of philanthropy, academic and independent study IECIA.
    The ECI awards is the first universal honor to utilize an evaluation
    standard to measure “The effectiveness of Innovation”. It recognizes those
    people and organizations with excellent achievements in business models,
    products, services, technology, marketing and management, which inspired the
    implementation of commercial and creative industries around the world. The
    ECI Awards has been renowned as the “Nobel Prize” for defining the future of
    the digital economy.

    <br /><br />
    <b>
      ECI Awards 2023<br />
      Organized by IECIA<br />
      (International Entrepreneurs, Creatives and Innovators Association)<br /><br />

      Hosted by ECI Awards Committee</b
    >
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
