<template>
  <div class="entry_text">
    <div class="entry_banner">
      <img src="@/assets/images/entry.png" />
    </div>
    All innovation efforts in digital business that were operating between
    January 1, 2021 and June 7,2022 are eligible to enter ECI Awards 2022. The
    entry could be a completed work, an ongoing work or a business plan. The
    enterprise can be brick-and-mortar establishment using digitalmeans or by
    internet enterprises.
    <br />
    <br />
    It is helpful to include context, data and results prior to the eligibility
    period. This enables judges to better understand the significance of your
    objective set and results achieved during the eligibility time period.
    However, only elements of the work that have happened within the time frame
    will be judged. If your case is ongoing, judges also appreciate your future
    outlook. All the case materials must be submitted through online entry
    system. After submitting your entry, a letter of confirmation will be sent
    to your email addres automatically. Once submission is completed, you will
    not be able to make any changes to your case on your own.
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
