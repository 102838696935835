<template>
  <div class="entry_text">
    <div class="entry_banner">
      <img src="@/assets/images/entry2.png" />
    </div>
    <div class="t1">Entry Price:</div>
    <!-- Early Bird: June 1-June 30, $650 <br />
    Regular: July 1-July 31, $800<br />
    VIP: August 1-August 31, $950 <br /> -->
    First Round: April 27th—May 31st, $550<br />
    Second Round: June 1st-June 30th, $700<br />
    Third Round: July 1st-July 31st, $850<br />
    Fourth Round: August1st-August31st, $950<br />
    Fifth Round: September 1st-September 30th, $1100<br /><br />
    <div class="t1">Important Dates:</div>
    Final Judging: Sept 2023 ECI Awards Gala & 2023 ECI Festival: Nov 2023 in
    Shanghai
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>
