<template>
  <div class="entry_text">
    <div class="t1">1. Reasons for Disqualification</div>
    The following will result in disqualification:<br />
    · Failing to follow the formatting requirements as outlined in the entry kit
    or the entry form.<br />
    · Infringement of intellectual property rights.<br />
    · Failing to make a payment for your entries by the submission deadline.<br /><br />
    <div class="t1">2. Conflict of Interests</div>
    In the judging process, jurors are allocated cases that do not prove a
    conflict of interest. For example, jurors are not allowed to review, score
    or discuss the cases from their own companies or competitors.<br />
    <div class="t1">3. Judging Process</div>
    Your entries will be read and judged by some of the most experienced (and
    most critical) business leaders. Entries are judged in two phases, and
    scoring is done anonymously and confidentially. <br />
    ECI Awards has two rounds of judging. There is a judge meeting in between to
    decide the lowest scores to enter the final, and the finalists. The
    Preliminary Judging happens online. Every entry is reviewed and scored by at
    least five judges, and the mean is its final score. The Final Judging is
    offline judging. Judges review, score, and discuss the cases on-site, to
    determine the bronze, silver and gold winners of each category. It is
    possible that a category may produce no winners at all.<br />
    <br />
    <div class="t1">4. Scoring Criteria</div>
    Judges will evaluate and provide four separate scores analyzing following
    aspects: <br /><br />
    <table class="table_">
      <tr>
        <td width="300">Background + Objectives</td>
        <td width="300">10%</td>
      </tr>
      <tr>
        <td>Solutions</td>
        <td>30%</td>
      </tr>
      <tr>
        <td>Execution</td>
        <td>30%</td>
      </tr>
      <tr>
        <td>Conversion Value</td>
        <td>30%</td>
      </tr>
      <tr>
        <td>Final Score</td>
        <td>100%</td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';

.table_ tr:nth-child(odd) {
  background: #eaeef3;
}
</style>
