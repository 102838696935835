<template>
  <div class="entry_text">
    <div class="t1">A.Marketing Innovation</div>
    <div class="t2">A1- Marketing Innovation - Industry</div>
    Description of secondary category: It aims to recognize innovative cases or works that promote the development of
    industries, enterprises, institutions or brands through marketing in different industries, including but not limited
    to: agriculture, forestry, animal husbandry and fishery, food, clothing, life services, daily chemical products,
    transportation, health, education and training, 3C digital, home and office, sports, entertainment, tourism, finance,
    pets, institutions and organizations, etc.
    <div class="t2">A2- Marketing Innovation - Model</div>
    Description of Secondary category: It aims to discover the cases or works that promote the development of the
    industry, enterprise, institution or brand through different marketing innovation activities, mainly including:
    investigation and research, resources and channels, integrated marketing, creative design, production, public
    relations and other marketing methods.
    <div class="t1">B- Product & Service Innovation</div>
    Primary Category Description: It aims to recognize [hard technology and intelligent hardware products] that achieve
    innovation through science and technology and industrial design (including but not limited to appearance, materials,
    form, application, function, use, etc.); [digital software products and services] that achieve innovation through the
    use of advanced technology, advanced UI design, friendly interaction, improve user experience, and [Consumer products
    and services] achieve innovation through including but not limited to material, technology, mode, culture, scene,
    experience and other aspects.
    <div class="t1">C.Category Description of [Model Innovation]</div>
    Model innovation recognizes models that have generated or are expected to generate business value through business
    model innovation, service model innovation, and management model innovation in the process of enterprise operation.
    <div class="t1">D.Category Description of [MarTech Innovation]</div>
    ECI@MarTech Awards recognize different roles in the MarTech industry chain which are MarTech technology provider,
    brand owner /MarTech user, Agency/ MarTech server, etc. to provide strategic solutions/technical
    solutions/products/industry research/industry insights and other innovative results.<br>
    They include industry consulting, industry research and tool providers in the MarTech industry chain, such as: CDP
    provider, middle platform provider, marketing automation, sales automation, private domain operation, member loyalty
    management, content ecology, advertising technology, digital operation, AI technology, data technology, overall
    solution provider, industry investors, brand customers, service providers and other fields.
    <div class="t1">E.Category Description of [AI Application Innovation]</div>
    It refers to the innovation and application of AI technology, which greatly improves product experience, marketing
    mode, or business model, management model, and service model, and has produced commercial value, or will produce
    commercial value.
    <div class="t1">F.Category Description of [Metaverse and AIGC Innovation]</div>
    From the perspective of stimulating creativity and enhancing content diversity, Metauniverse and AIGC innovation
    evaluate the cost reduction and efficiency of the creativity, strategy, model of the entries. The cases will focus on
    the interface application development and practical operation of digital ecological platforms including large model,
    generative AI, ChatGPT, etc. Through the judging of innovative technology cases, it aims to promote the integration
    and popularization of new technologies, including VR/AR, smart wear, biometrics, digital twinning, industrial
    Internet, block chain, NFT, artificial intelligence and computing power, promote the application of meta-universe
    innovative technologies, and guide and promote the development of meta-universe industry. To build more enabling
    scenes and practice platforms for the government and innovative enterprises.
    <div class="t1">G.Category Description of Public Welfare Innovation</div>
    The category celebrates creativity that drives cultural change through ideas and innovations that change the world and
    have a positive impact on gender inequalities, imbalances or inequities. For example, by consciously highlighting
    gender equality in advertising, works that subtly or explicitly fight against gender inequality or prejudice.
    <div class="t1">H.Category Description of [Future Village Innovation]</div>
    This category recognizes global benchmark innovation in rural construction and practices, including creative design,
    business model, service model, etc., with the aim of guiding and promoting the construction and development of
    future-oriented and beautiful villages.
  </div>
</template>

<script>
export default {
  name: 'entry',
  components: {},
  data() {
    return {};
  },
  created() { },
  methods: {},
};
</script>
<style lang="scss" scoped>
@import '@/assets/styles/entryDetail.scss';
</style>



